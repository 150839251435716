<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create Status Type
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="rosterStatusTypeCreateForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="submitForm">
        <b-card>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Status Type Name*"
                label-for="h-roster-status-type-name"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-roster-status-type-name"
                    v-model="name"
                    placeholder="Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6" />

            <b-col cols="6">
              <b-form-group
                label="Abbreviation*"
                label-for="h-roster-status-type-abbreviation"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Abbreviation"
                  vid="abbreviation"
                  rules="required"
                >
                  <b-form-input
                    id="h-roster-status-type-abbreviation"
                    v-model="abbreviation"
                    placeholder="Abbreviation"
                    :state="(errors.length > 0 || abbreviationValidation) ? false : null"
                    name="abbreviation"
                    @input="abbreviationValidation == true ? abbreviationValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="abbreviationValidation"
                    class="text-danger"
                  >
                    {{ abbreviationError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6" />

            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                name="Color"
                vid="color"
                rules="required"
              >
                <b-form-group
                  label="Color*"
                  label-for="h-roster-status-type-color"
                  label-cols-md="3"
                  :state="(errors.length > 0 || colorValidation) ? false : null"
                >
                  <b-form-input
                    id="h-roster-status-type-color"
                    v-model="color"
                    type="color"
                    :state="(errors.length > 0 || colorValidation) ? false : null"
                    name="color"
                    @input="colorValidation == true ? colorValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="colorValidation"
                    class="text-danger"
                  >
                    {{ colorError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6" />

            <b-col cols="6">
              <b-form-group
                label="Allow Assignment*"
                label-for="h-roster-status-type-allowAssignment"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Allow Assignment"
                  vid="allowAssignment"
                  rules="required"
                >
                  <b-form-radio-group
                    id="h-roster-status-type-allowAssignment"
                    v-model="allowAssignment"
                    button-variant="outline-primary"
                    buttons
                    class="spaceBetweenRadio"
                  >
                    <b-form-radio
                      v-for="(assign, kindex) in assignmentOptions"
                      :key="kindex"
                      :value="assign.value"
                    >
                      <feather-icon
                        v-if="allowAssignment === assign.value"
                        icon="CheckIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">{{ assign.text }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="allowAssignmentValidation"
                    class="text-danger"
                  >
                    {{ allowAssignmentError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6" />

            <b-col cols="9">
              <b-form-group
                label="Remarks"
                label-for="h-event-remarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
                  rules=""
                >
                  <b-form-textarea
                    id="h-event-remarks"
                    v-model="remarks"
                    placeholder="Remarks"
                    :state="(errors.length > 0 || remarksValidation) ? false : null"
                    name="remarks"
                    @input="remarksValidation == true ? remarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="remarksValidation"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-roster-status-type-status"
                  label-cols-md="3"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-roster-status-type-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'operation-roster-status-types-index' }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Status Type</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BFormTextarea, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  // eslint-disable-next-line import/named
  required, maxValue, minValue, numeric, between,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BFormRadioGroup,
    BButton,
    BNav,
    BFormTextarea,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      abbreviation: '',
      color: '#e2c5c5',
      remarks: '',
      allowAssignment: 'show warning',
      status: 'active',
      nameError: 'Valid date period is required',
      nameValidation: false,
      abbreviationError: 'Valid operating hours is required',
      abbreviationValidation: false,
      colorValidation: false,
      colorError: 'Valid color is required',
      remarksError: 'Valid operating hours is required',
      remarksValidation: false,
      dayOffDaysError: 'Valid applicable days is required',
      dayOffDaysValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      allowAssignmentError: 'Valid value is required',
      allowAssignmentValidation: false,
      option: [{ title: 'Active', code: 'active' }, { title: 'Inactive', code: 'inactive' }],
      assignmentOptions: [
        { text: 'Show Warning', value: 'show warning' },
        { text: 'Yes', value: 'yes' },
      ],

      // validation rules
      required,
      numeric,
      maxValue,
      minValue,
      between,
    }
  },
  methods: {
    submitForm() {
      this.$refs.rosterStatusTypeCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('abbreviation', this.abbreviation)
          formData.append('remarks', this.remarks)
          formData.append('color', this.color)
          formData.append('allowAssignment', this.allowAssignment)
          formData.append('status', this.status)

          this.$http.post('operation/roster-status-types/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Status Type Created.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'operation-roster-status-types-index' })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'abbreviation') {
                    this.abbreviationError = validationError.msg
                    this.abbreviationValidation = true
                  } else if (validationError.param === 'remarks') {
                    this.remarksError = validationError.msg
                    this.remarksValidation = true
                  } else if (validationError.param === 'color') {
                    this.colorError = validationError.msg
                    this.colorValidation = true
                  } else if (validationError.param === 'allowAssignment') {
                    this.allowAssignmentError = validationError.msg
                    this.allowAssignmentValidation = true
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .customCheckbox {
    border-right-width: 1px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-left-width: 1px !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .btn-group, .btn-group-vertical {
    position: relative;
    display: contents;
    vertical-align: middle;
  }
</style>
